<template>
  <div class="page">
    <div class="box1">
      <div class="main_box">
        <div class="ttl">全风险：<br />安全管理解决方案</div>
        <div class="sub_ttl">
          全盘掌控安全风险，提升工人安全意识，为工程安全保驾护航
        </div>
        <a
          class="btn"
          href="http://p.qiao.baidu.com/cps/chat?siteId=13160731&userId=27403999&siteToken=839057cc7afae96bcdd508339dc7a783"
          target="_blank"
        >
          咨询服务
        </a>
      </div>
    </div>
    <div class="box5">
      <div class="title">安全管理存在什么问题？</div>
      <div class="main_box" flex="main:justify">
        <div class="col c1">
          <div class="info">
            <div class="ttl">安全管理效率低</div>
            <div class="desc">
              使用传统口述、罚单等方式管理施工人员，管理效率低下，且效果不明显。
            </div>
          </div>
        </div>
        <div class="col c2">
          <div class="info">
            <div class="ttl">安全意识薄弱</div>
            <div class="desc">
              施工管理流程不规范，工人对于施工安全意识薄弱，导致施工事故频繁发生。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box2">
      <div class="title">如何提升安全管理效率</div>
      <div class="main_box" flex="main:justify cross:center">
        <div class="phone_swp" flex-box="0">
          <swiper
            @ready="(sw) => (box2.swiper = sw)"
            @slideChange="() => (box2.crtIndex = box2.swiper.realIndex)"
            class="swp swiper-no-swiping"
            :options="{ autoplay: { delay: 4000 } }"
          >
            <swiper-slide
              class="sld"
              v-for="(s, idx) in box2.slides"
              :key="idx"
            >
              <img class="pic" :src="s.pic" alt="s.ttl" />
            </swiper-slide>
          </swiper>
          <div class="pagination" flex="main:center">
            <div
              :class="['dot', box2.crtIndex === i ? 'z-crt' : '']"
              @click="box2.swiper.slideTo(i)"
              v-for="(item, i) in box2.slides"
              :key="i"
            />
          </div>
        </div>
        <div class="row_tabs" flex-box="0">
          <div
            :class="['item', box2.crtIndex === idx ? 'z-crt' : '']"
            flex="dir:left"
            v-for="(s, idx) in box2.slides"
            :key="idx"
            @click="box2.swiper.slideTo(idx)"
          >
            <div class="num">{{ idx + 1 }}</div>
            <div>
              <div class="ttl">{{ s.ttl }}</div>
              <div class="desc">{{ s.desc }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box4">
      <div class="title">如何提升工人安全意识</div>
      <div class="main_box" flex="main:justify cross:center">
        <div class="row_tabs" flex-box="0">
          <div
            :class="['item', box4.crtIndex === idx ? 'z-crt' : '']"
            flex="dir:left"
            v-for="(s, idx) in box4.slides"
            :key="idx"
            @click="box4.swiper.slideTo(idx)"
          >
            <div class="num">{{ idx + 1 }}</div>
            <div>
              <div class="ttl">{{ s.ttl }}</div>
              <div class="desc">{{ s.desc }}</div>
            </div>
          </div>
        </div>
        <div class="phone_swp" flex-box="0">
          <swiper
            @ready="(sw) => (box4.swiper = sw)"
            @slideChange="() => (box4.crtIndex = box4.swiper.realIndex)"
            class="swp swiper-no-swiping"
            :options="{ autoplay: { delay: 4000 } }"
          >
            <swiper-slide
              class="sld"
              v-for="(s, idx) in box4.slides"
              :key="idx"
            >
              <img class="pic" :src="s.pic" alt="s.ttl" />
            </swiper-slide>
          </swiper>
          <div class="pagination" flex="main:center">
            <div
              :class="['dot', box4.crtIndex === i ? 'z-crt' : '']"
              @click="box4.swiper.slideTo(i)"
              v-for="(item, i) in box4.slides"
              :key="i"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="box3">
      <div class="title">“AI摄像头”智能识别违章行为</div>
      <div class="main_box">
        <div class="sub_ttl">
          可对进入作业区域的人员进行自动识别，若检测到人员未佩戴安全帽、未穿反光衣、发现明火、烟雾等情况，系统会立即发起报警信号，报警信号同步推送至管理员，同时形成抓拍台账，保存于系统后台数据库。
        </div>
        <div class="detail_btn" @click.stop="showDeviceDetail('005')">
          查看设备详情
        </div>
        <div class="pics" flex="main:justify">
          <img class="item" src="@/assets/img/摄像头_1.jpg" alt="未戴安全帽" />
          <img class="item" src="@/assets/img/摄像头_2.jpg" alt="未穿反光衣" />
          <img class="item" src="@/assets/img/摄像头_3.jpg" alt="发现明火" />
        </div>
      </div>
    </div>
    <div class="trial_bar">
      <div class="main_box" flex="main:justify cross:center">
        <div class="ttl">为您打造一套数智工程管理解决方案</div>
        <div class="btn" @click="showTrialModal">申请体验</div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Footer from "@/components/Footer.vue";

const baseUrl = `${process.env.BASE_URL}public/img/手机截图`;

const box2 = {
  swiper: null,
  crtIndex: 0,
  slides: [
    {
      ttl: "AI识别",
      desc: "自动识别违章人员，并关联相应的班组或分包单位",
      pic: `${baseUrl}/安全管理_1_1.png`,
    },
    {
      ttl: "一键生成",
      desc: "一键在线生成警告单、整改单、罚款单相应处罚单据",
      pic: `${baseUrl}/安全管理_1_2.png`,
    },
    {
      ttl: "违章记录",
      desc: "记录在班组及工友履历中，以便后期培训及用工决策",
      pic: `${baseUrl}/安全管理_1_3.png`,
    },
    {
      ttl: "整改对比",
      desc: "在线可进行整改前、复检中，整改后记录及对比",
      pic: `${baseUrl}/安全管理_1_4.png`,
    },
    {
      ttl: "数据分析",
      desc: "用工安全问题智能分析，常发生问题重点关注防范",
      pic: `${baseUrl}/安全管理_1_5.png`,
    },
    {
      ttl: "提供隐患库",
      desc: "提供安全隐患库，选择并自动描述问题及整改说明",
      pic: `${baseUrl}/安全管理_1_6.png`,
    },
  ],
};

const box4 = {
  swiper: null,
  crtIndex: 0,
  slides: [
    {
      ttl: "短视频",
      desc: "解决传统安全教育走流程，排拆学习，低效学习的痛",
      pic: `${baseUrl}/安全管理_2_1.png`,
    },
    {
      ttl: "奖学金",
      desc: "刷视频，抢红包，一边学习一边赚钱，上下班两不误",
      pic: `${baseUrl}/安全管理_2_2.png`,
    },
    {
      ttl: "碎片成长",
      desc: "上班实操，闲时成长，早日成长，早日当班组长",
      pic: `${baseUrl}/安全管理_2_3.png`,
    },
    {
      ttl: "学习排位",
      desc: "企业根据学习排位，树一批并奖励一批优秀的建设者",
      pic: `${baseUrl}/安全管理_2_4.png`,
    },
  ],
};

export default {
  components: {
    Footer,
  },
  data() {
    return {
      box2,
      box4,
    };
  },
  methods: {
    ...mapActions(["showTrialModal", "showDeviceDetail"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/theme.scss";

.box1 {
  background: url("~@/assets/bg/banner 3.jpg") no-repeat center center;
  background-size: cover;
  height: 32rem;
  overflow: hidden;

  .ttl {
    margin-top: 13.8rem;
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    line-height: 2.8rem;
    letter-spacing: 0.2rem;
  }

  .sub_ttl {
    margin-top: 1.1rem;
    font-size: 1rem;
    color: #fff;
    line-height: 1.5rem;
  }

  .btn {
    margin-top: 3rem;
    display: block;
    width: 6.6rem;
    height: 2rem;
    background-color: #2574ff;
    border-radius: 0.4rem;
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
    color: #fff;
    line-height: 2rem;

    &:hover {
      background-color: #045af2;
    }
  }
}

.box3 {
  overflow: hidden;
  background-color: #f0f1f6;
  padding: 7rem 0 6.4rem;

  .title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #1a1a1a;
    line-height: 3rem;
  }

  .main_box {
    overflow: hidden;

    .sub_ttl {
      margin: 1rem 4rem 0;
      font-size: 1rem;
      text-align: center;
      color: #666;
      line-height: 1.5rem;
    }

    .detail_btn {
      margin: 2rem auto 0;
      width: 8rem;
      height: 2rem;
      background-color: #2574ff;
      border-radius: 0.4rem;
      line-height: 2rem;
      text-align: center;
      font-size: 0.9rem;
      font-weight: bold;
      color: #fff;
      cursor: pointer;

      &:hover {
        background-color: #045af2;
      }
    }

    .pics {
      margin-top: 3.5rem;

      .item {
        display: block;
        width: 21.25rem;
        height: 12.8rem;
      }
    }
  }
}

.box5 {
  padding: 7rem 0;
  background-color: #fff;

  .title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #1a1a1a;
    line-height: 3rem;
  }

  .main_box {
    margin-top: 3rem;

    .col {
      flex-shrink: 0;
      position: relative;
      width: 33rem;
      height: 20rem;
      color: #fff;
      background-size: cover;
      background-position: center center;

      &.c1 {
        background-image: url("~@/assets/bg/安全管理效率低.jpg");
      }

      &.c2 {
        background-image: url("~@/assets/bg/安全意识薄弱.jpg");
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0.6)
        );
      }

      .info {
        position: relative;
        z-index: 1;
        margin: 12rem 2.5rem 0;
      }

      .ttl {
        font-size: 1.6rem;
        font-weight: bold;
        color: #fff;
        line-height: 2.25rem;
      }

      .desc {
        margin-top: 0.8rem;
        font-size: 1rem;
        color: #fff;
        line-height: 1.5rem;
      }
    }
  }
}

.box2 {
  overflow: hidden;
  background-color: #f0f1f6;
  padding: 7rem 0;

  .title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #1a1a1a;
    line-height: 3rem;
  }

  .main_box {
    margin-top: 7rem;
    width: 56.35rem;
    height: 31rem;
    background: url("~@/assets/bg/装饰背景.png") no-repeat center center;
    background-size: 100% 100%;
    padding-left: 5rem;
    box-sizing: border-box;
  }
}

.box4 {
  overflow: hidden;
  background-color: #fff;
  padding: 7rem 0;

  .title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #1a1a1a;
    line-height: 3rem;
  }

  .main_box {
    margin-top: 7rem;
    width: 56.35rem;
    height: 31rem;
    background: url("~@/assets/bg/装饰背景2.png") no-repeat right center;
    background-size: auto 100%;
    padding-right: 4.5rem;
    box-sizing: border-box;
  }
}

.trial_bar {
  background-color: #f0f1f6;
  padding-bottom: 1.6rem;

  .main_box {
    background: url("~@/assets/bg/申请体验背景.png") no-repeat center center;
    background-size: cover;
    height: 7rem;
  }

  .ttl {
    margin-left: 4rem;
    font-size: 1.8rem;
    font-weight: bold;
    color: #fff;
    line-height: 2.5rem;
    letter-spacing: 0.15rem;
  }

  .btn {
    margin-right: 4rem;
    width: 7rem;
    height: 2.4rem;
    background-color: #fff;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    color: #0078f0;
    line-height: 2.4rem;
    cursor: pointer;

    &:hover {
      background-color: #dce8ff;
    }
  }
}

.main_box {
  width: $mainWidth;
  margin: auto;
}

.row_tabs {
  width: 24rem;

  .item {
    margin-bottom: 2rem;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &.z-crt {
      .num {
        opacity: 1;
      }

      .ttl {
        font-size: 1.2rem;
        line-height: 1.8rem;
        margin-top: 0;
      }
    }

    .num {
      width: 1.7rem;
      height: 1.7rem;
      line-height: 1.7rem;
      text-align: center;
      background-color: #0078f0;
      border-radius: 0.6rem;
      opacity: 0.2;
      font-size: 1rem;
      font-weight: bold;
      color: #fff;
      margin-right: 0.8rem;
    }

    .ttl {
      font-size: 0.9rem;
      font-weight: bold;
      color: #1a1a1a;
      line-height: 1.35rem;
      margin-top: 0.2rem;
    }

    .desc {
      margin-top: 0.4rem;
      font-size: 0.7rem;
      color: #9a9a9a;
      line-height: 1.1rem;
    }
  }
}

.phone_swp {
  position: relative;
  width: 14.9rem;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 30.55rem;
    z-index: 1;
    background: url("~@/assets/bg/1手机边框.png") center center no-repeat;
    background-size: contain;
  }

  .swp {
    width: 13rem;
    height: 28.15rem;
    margin: 0.9rem auto 0;

    .pic {
      display: block;
      width: 100%;
    }
  }

  .pagination {
    margin-top: 1.8rem;

    .dot {
      width: 0.6rem;
      height: 0.15rem;
      opacity: 0.4;
      background-color: #2574ff;
      border-radius: 0.1rem;
      cursor: pointer;
      transition: opacity 0.3s;
      margin-right: 0.4rem;

      &:last-child {
        margin-right: 0;
      }

      &.z-crt {
        opacity: 1;
      }
    }
  }
}
</style>
